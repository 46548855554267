
export const images = [
  {
    src: "images/home/img_1.png",
    original: "images/home/img_1.png",
    width: 320,
    height: 174,
    tags: [
      { value: "Award", title: "Award" },
      { value: "Professional", title: "Professional" },
    ],
    caption: "Professional Fellowship Doctorate award",
  },
  {
    src: "images/home/img_2.png",
    original: "images/home/img_2.png",
    width: 320,
    height: 212,
    caption: "Professional Fellowship Doctorate award",
  },
  {
    src: "images/home/img_3.png",
    original: "images/home/img_3.png",
    width: 320,
    height: 212,
    caption: "Professional Fellowship Doctorate award",
  },
  {
    src: "images/home/img_4.png",
    original: "images/home/img_4.png",
    width: 320,
    height: 215,
    tags: [{ value: "Award", title: "Award" }],
    caption: "Professional Fellowship Doctorate award",
  },
  {
    src: "images/home/img_5.png",
    original: "images/home/img_5.png",
    width: 257,
    height: 320,
    caption: "Professional Fellowship Doctorate award",
  },
  {
    src: "images/home/img_6.png",
    original: "images/home/img_6.png",
    width: 226,
    height: 320,
    caption: "Professional Fellowship Doctorate award",
  },
  {
    src: "images/home/img_7.png",
    original: "images/home/img_7.png",
    width: 320,
    height: 215,
    tags: [{ value: "Fellowship", title: "Fellowship" }],
    caption: "Professional Fellowship Doctorate award",
  },
  {
    src: "images/home/img_8.png",
    original: "images/home/img_8.png",
    width: 257,
    height: 320,
    caption: "Professional Fellowship Doctorate award",
  },
  {
    src: "images/home/img_9.png",
    original: "images/home/img_9.png",
    width: 226,
    height: 320,
    caption: "Professional Fellowship Doctorate award",
  },








  {
    src: "images/home/image-1.png",
    original: "images/home/image-1.png",
    width: 320,
    height: 174,
    tags: [
      { value: "guard", title: "guard" },
      { value: "quarter", title: "quarter" },
    ],
    caption: "Inspecting quarter guard at  the AIG office",
  },
  {
    src: "images/home/image-2.png",
    original: "images/home/image-2.png",
    width: 320,
    height: 212,
    caption: "Inspecting quarter guard at  the AIG office",
  },
  {
    src: "images/home/image-3.png",
    original: "images/home/image-3.png",
    width: 320,
    height: 212,
    caption: "Courtesy visit to the AIG",
  },
  {
    src: "images/home/image-4.png",
    original: "images/home/image-4.png",
    width: 320,
    height: 215,
    tags: [{ value: "Police", title: "Police" }],
    caption: "Courtesy visit to the AIG",
  },
  {
    src: "images/home/image-5.png",
    original: "images/home/image-5.png",
    width: 257,
    height: 320,
    caption: "Inspecting quarter guard at  the AIG office",
  },
  {
    src: "images/home/image-6.png",
    original: "images/home/image-6.png",
    width: 226,
    height: 320,
    caption: "Courtesy visit to the AIG",
  },
  {
    src: "images/home/image-7.png",
    original: "images/home/image-7.png",
    width: 320,
    height: 213,
    caption: "Inspecting quarter guard at  the AIG office",
  },
  {
    src: "images/home/image-8.png",
    original: "images/home/image-8.png",
    width: 320,
    height: 183,
    caption: "Inspecting quarter guard at  the AIG office",
  },
  {
    src: "images/home/image-9.png",
    original: "images/home/image-9.png",
    width: 240,
    height: 320,
    tags: [{ value: "Nature", title: "Nature" }],
    caption: "Courtesy visit to the AIG",
  },
  {
    src: "images/home/image-10.png",
    original: "images/home/image-10.png",
    width: 320,
    height: 190,
    caption: "With the AIG",
  },
  {
    src: "images/home/image-11.png",
    original: "images/home/image-11.png",
    width: 320,
    height: 148,
    tags: [{ value: "", title: "" }],
    caption: "",
  },
  {
    src: "images/home/image-12.png",
    original: "images/home/image-12.png",
    width: 320,
    height: 213,
    caption: "",
  },
  {
    src: "images/home/image-13.png",
    original: "images/home/image-13.png",
    alt: "Big Ben - London",
    width: 248,
    height: 320,
    caption: "",
  },
  {
    src: "images/home/image-14.png",
    original: "images/home/image-14.png",
    alt: "Red Zone - Paris",
    width: 320,
    height: 113,
    tags: [{ value: "", title: "" }],
    caption: "",
  },
  {
    src: "images/home/image-15.png",
    original: "images/home/image-15.png",
    alt: "Wood Glass",
    width: 313,
    height: 320,
    caption: "",
  },
  {
    src: "images/home/image-16.png",
    original: "images/home/image-16.png",
    width: 320,
    height: 213,
    caption: "",
  },
  {
    src: "images/home/image-17.png",
    original: "images/home/image-17.png",
    width: 320,
    height: 194,
    caption: "",
  },
  {
    src: "images/home/image-18.png",
    original: "images/home/image-18.png",
    alt: "Cosmos Flower",
    width: 320,
    height: 213,
    caption: "",
  },
  {
    src: "images/home/image-19.png",
    original: "images/home/image-19.png",
    width: 271,
    height: 320,
    caption: "",
  },
  {
    src: "images/home/image-20.png",
    original: "images/home/image-20.png",
    width: 320,
    height: 213,
    tags: [
      { value: "", title: "" },
      { value: "", title: "" },
    ],
    caption: "",
  },
  {
    src: "images/home/image-21.png",
    original: "images/home/image-21.png",
    width: 320,
    height: 213,
    tags: [
      { value: "", title: "" },
      { value: "", title: "" },
    ],
    caption: "",
  },
  {
    src: "images/home/image-22.png",
    original: "images/home/image-22.png",
    width: 320,
    height: 213,
    caption: "",
  },
  {
    src: "images/home/image-23.png",
    original: "images/home/image-23.png",
    width: 320,
    height: 213,
    caption: "",
  },
  {
    src: "images/home/image-24.png",
    original: "images/home/image-24.png",
    width: 320,
    height: 179,
    tags: [
      { value: "", title: "" },
      { value: "", title: "" },
    ],
    caption: "",
  },
  {
    src: "images/home/image-25.png",
    original: "images/home/image-25.png",
    width: 320,
    height: 213,
    caption: "",
  },
];