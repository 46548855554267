const images = [
  {
    original: "images/photos/carousel/img-1.jpg",
    thumbnail: "images/photos/carousel/img-1-t.jpg",
    description: "placeholder"
  },
  {
    original: "images/photos/carousel/img-2.jpg",
    thumbnail: "images/photos/carousel/img-2-t.jpg",
    description: "placeholder"
  },
  {
    original: "images/photos/carousel/img-3.jpg",
    thumbnail: "images/photos/carousel/img-3-t.jpg",
    description: "placeholder"
  },
  {
    original: "images/photos/carousel/img-4.jpg",
    thumbnail: "images/photos/carousel/img-4-t.jpg",
    description: "placeholder"
  },
  {
    original: "images/photos/carousel/img-5.jpg",
    thumbnail: "images/photos/carousel/img-5-t.jpg",
    description: "placeholder"
  },
  {
    original: "images/photos/carousel/img-6.jpg",
    thumbnail: "images/photos/carousel/img-6-t.jpg",
    description: "placeholder"
  },
  {
    original: "images/photos/carousel/img-7.jpg",
    thumbnail: "images/photos/carousel/img-7-t.jpg",
    description: "placeholder"
  },
  {
    original: "images/photos/carousel/img-8.jpg",
    thumbnail: "images/photos/carousel/img-8-t.jpg",
    description: "placeholder"
  },
  {
    original: "images/photos/carousel/img-9.jpg",
    thumbnail: "images/photos/carousel/img-9-t.jpg",
    description: "placeholder"
  },
  {
    original: "images/photos/carousel/img-10.jpg",
    thumbnail: "images/photos/carousel/img-10-t.jpg",
    description: "placeholder"
  },
  {
    original: "images/photos/carousel/img-11.jpg",
    thumbnail: "images/photos/carousel/img-11-t.jpg",
    description: "placeholder"
  },
  {
    original: "images/photos/carousel/img-12.jpg",
    thumbnail: "images/photos/carousel/img-12-t.jpg",
    description: "placeholder"
  },
  {
    original: "images/photos/carousel/img-13.jpg",
    thumbnail: "images/photos/carousel/img-13-t.jpg",
    description: "placeholder"
  },
  {
    original: "images/photos/carousel/img-14.jpg",
    thumbnail: "images/photos/carousel/img-14-t.jpg",
    description: "placeholder"
  },
  {
    original: "images/photos/carousel/img-15.jpg",
    thumbnail: "images/photos/carousel/img-15-t.jpg",
    description: "placeholder"
  },
  {
    original: "images/photos/carousel/img-16.jpg",
    thumbnail: "images/photos/carousel/img-16-t.jpg",
    description: "placeholder"
  },
  {
    original: "images/photos/carousel/img-17.jpg",
    thumbnail: "images/photos/carousel/img-17-t.jpg",
    description: "placeholder"
  },
  {
    original: "images/photos/carousel/img-18.jpg",
    thumbnail: "images/photos/carousel/img-18-t.jpg",
    description: "placeholder"
  },
  {
    original: "images/photos/carousel/img-19.jpg",
    thumbnail: "images/photos/carousel/img-19-t.jpg",
    description: "placeholder"
  },
  {
    original: "images/photos/carousel/img-20.jpg",
    thumbnail: "images/photos/carousel/img-20-t.jpg",
    description: "placeholder"
  },
  {
    original: "images/photos/carousel/img-21.jpg",
    thumbnail: "images/photos/carousel/img-21-t.jpg",
    description: "placeholder"
  },
  {
    original: "images/photos/carousel/img-22.jpg",
    thumbnail: "images/photos/carousel/img-22-t.jpg",
    description: "placeholder"
  },
  {
    original: "images/photos/carousel/img-23.jpg",
    thumbnail: "images/photos/carousel/img-23-t.jpg",
    description: "placeholder"
  },
  {
    original: "images/photos/carousel/img-24.jpg",
    thumbnail: "images/photos/carousel/img-24-t.jpg",
    description: "placeholder"
  },
  {
    original: "images/photos/carousel/img-25.jpg",
    thumbnail: "images/photos/carousel/img-25-t.jpg",
    description: "placeholder"
  },
  {
    original: "images/photos/carousel/img-26.jpg",
    thumbnail: "images/photos/carousel/img-26-t.jpg",
    description: "placeholder"
  },
  {
    original: "images/photos/carousel/img-27.jpg",
    thumbnail: "images/photos/carousel/img-27-t.jpg",
    description: "placeholder"
  },
  {
    original: "images/photos/carousel/img-28.jpg",
    thumbnail: "images/photos/carousel/img-28-t.jpg",
    description: "placeholder"
  },
  {
    original: "images/photos/carousel/img-29.jpg",
    thumbnail: "images/photos/carousel/img-29-t.jpg",
    description: "placeholder"
  },
  {
    original: "images/photos/carousel/img-30.jpg",
    thumbnail: "images/photos/carousel/img-30-t.jpg",
    description: "placeholder"
  },
];

export default images