
export const Images = [
  {
    src: "images/home/image-1.png",
    original: "images/home/image-1.png",
    width: 320,
    height: 174,
    tags: [
      { value: "guard", title: "guard" },
      { value: "quarter", title: "quarter" },
    ],
    caption: "Inspecting quarter guard at  the AIG office",
  },
  {
    src: "images/home/image-2.png",
    original: "images/home/image-2.png",
    width: 320,
    height: 212,
    caption: "Inspecting quarter guard at  the AIG office",
  },
  {
    src: "images/home/image-3.png",
    original: "images/home/image-3.png",
    width: 320,
    height: 212,
    caption: "Courtesy visit to the AIG",
  },
  {
    src: "images/home/image-4.png",
    original: "images/home/image-4.png",
    width: 320,
    height: 215,
    tags: [{ value: "Police", title: "Police" }],
    caption: "Courtesy visit to the AIG",
  },
  {
    src: "images/home/image-5.png",
    original: "images/home/image-5.png",
    width: 257,
    height: 320,
    caption: "Inspecting quarter guard at  the AIG office",
  },
  {
    src: "images/home/image-6.png",
    original: "images/home/image-6.png",
    width: 226,
    height: 320,
    caption: "Courtesy visit to the AIG",
  },
  {
    src: "images/home/image-7.png",
    original: "images/home/image-7.png",
    width: 320,
    height: 213,
    caption: "Inspecting quarter guard at  the AIG office",
  },
  {
    src: "images/home/image-8.png",
    original: "images/home/image-8.png",
    width: 320,
    height: 183,
    caption: "Inspecting quarter guard at  the AIG office",
  },
  {
    src: "images/home/image-9.png",
    original: "images/home/image-9.png",
    width: 240,
    height: 320,
    tags: [{ value: "Nature", title: "Nature" }],
    caption: "Courtesy visit to the AIG",
  },
  {
    src: "images/home/image-10.png",
    original: "images/home/image-10.png",
    width: 320,
    height: 190,
    caption: "With the AIG",
  },
  {
    src: "images/home/image-11.png",
    original: "images/home/image-11.png",
    width: 320,
    height: 148,
    tags: [{ value: "", title: "" }],
    caption: "",
  },
  {
    src: "images/home/image-12.png",
    original: "images/home/image-12.png",
    width: 320,
    height: 213,
    caption: "",
  },
  {
    src: "images/home/image-13.png",
    original: "images/home/image-13.png",
    alt: "Big Ben - London",
    width: 248,
    height: 320,
    caption: "",
  },
  {
    src: "images/home/image-14.png",
    original: "images/home/image-14.png",
    alt: "Red Zone - Paris",
    width: 320,
    height: 113,
    tags: [{ value: "", title: "" }],
    caption: "",
  },
  {
    src: "images/home/image-15.png",
    original: "images/home/image-15.png",
    alt: "Wood Glass",
    width: 313,
    height: 320,
    caption: "",
  },
  {
    src: "images/home/image-16.png",
    original: "images/home/image-16.png",
    width: 320,
    height: 213,
    caption: "",
  },
  {
    src: "images/home/image-17.png",
    original: "images/home/image-17.png",
    width: 320,
    height: 194,
    caption: "",
  },
  {
    src: "images/home/image-18.png",
    original: "images/home/image-18.png",
    alt: "Cosmos Flower",
    width: 320,
    height: 213,
    caption: "",
  },
  {
    src: "images/home/image-19.png",
    original: "images/home/image-19.png",
    width: 271,
    height: 320,
    caption: "",
  },
  {
    src: "images/home/image-20.png",
    original: "images/home/image-20.png",
    width: 320,
    height: 213,
    tags: [
      { value: "", title: "" },
      { value: "", title: "" },
    ],
    caption: "",
  },
  {
    src: "images/home/image-21.png",
    original: "images/home/image-21.png",
    width: 320,
    height: 213,
    tags: [
      { value: "", title: "" },
      { value: "", title: "" },
    ],
    caption: "",
  },
  {
    src: "images/home/image-22.png",
    original: "images/home/image-22.png",
    width: 320,
    height: 213,
    caption: "",
  },
  {
    src: "images/home/image-23.png",
    original: "images/home/image-23.png",
    width: 320,
    height: 213,
    caption: "",
  },
  {
    src: "images/home/image-24.png",
    original: "images/home/image-24.png",
    width: 320,
    height: 179,
    tags: [
      { value: "", title: "" },
      { value: "", title: "" },
    ],
    caption: "",
  },
  {
    src: "images/home/image-25.png",
    original: "images/home/image-25.png",
    width: 320,
    height: 213,
    caption: "",
  },
  {
    src: "images/home/image-26.png",
    original: "images/home/image-26.png",
    width: 320,
    height: 174,
    tags: [
      { value: "guard", title: "guard" },
      { value: "quarter", title: "quarter" },
    ],
    caption: "Inspecting quarter guard at  the AIG office",
  },
  {
    src: "images/home/image-27.png",
    original: "images/home/image-27.png",
    width: 320,
    height: 212,
    caption: "Inspecting quarter guard at  the AIG office",
  },
  {
    src: "images/home/image-28.png",
    original: "images/home/image-28.png",
    width: 320,
    height: 212,
    caption: "Courtesy visit to the AIG",
  },
  {
    src: "images/home/image-29.png",
    original: "images/home/image-29.png",
    width: 320,
    height: 215,
    tags: [{ value: "Police", title: "Police" }],
    caption: "Courtesy visit to the AIG",
  },
  {
    src: "images/home/image-30.png",
    original: "images/home/image-30.png",
    width: 257,
    height: 320,
    caption: "Inspecting quarter guard at  the AIG office",
  },
  {
    src: "images/home/image-31.png",
    original: "images/home/image-31.png",
    width: 226,
    height: 320,
    caption: "Courtesy visit to the AIG",
  },
  {
    src: "images/home/image-32.png",
    original: "images/home/image-32.png",
    width: 320,
    height: 213,
    caption: "Inspecting quarter guard at  the AIG office",
  },
  {
    src: "images/home/image-33.png",
    original: "images/home/image-33.png",
    width: 320,
    height: 183,
    caption: "Inspecting quarter guard at  the AIG office",
  },
  {
    src: "images/home/image-34.png",
    original: "images/home/image-34.png",
    width: 240,
    height: 320,
    tags: [{ value: "Nature", title: "Nature" }],
    caption: "Courtesy visit to the AIG",
  },
  {
    src: "images/home/image-35.png",
    original: "images/home/image-35.png",
    width: 320,
    height: 190,
    caption: "With the AIG",
  },
  {
    src: "images/home/image-36.png",
    original: "images/home/image-36.png",
    width: 320,
    height: 148,
    tags: [{ value: "", title: "" }],
    caption: "",
  },
  {
    src: "images/home/image-37.png",
    original: "images/home/image-37.png",
    width: 320,
    height: 213,
    caption: "",
  },
  {
    src: "images/home/image-38.png",
    original: "images/home/image-38.png",
    alt: "Big Ben - London",
    width: 248,
    height: 320,
    caption: "",
  },
  {
    src: "images/home/image-39.png",
    original: "images/home/image-39.png",
    alt: "Red Zone - Paris",
    width: 320,
    height: 113,
    tags: [{ value: "", title: "" }],
    caption: "",
  },
  {
    src: "images/home/image-40.png",
    original: "images/home/image-40.png",
    alt: "Wood Glass",
    width: 313,
    height: 320,
    caption: "",
  },
  {
    src: "images/home/image-41.png",
    original: "images/home/image-41.png",
    width: 320,
    height: 213,
    caption: "",
  },
  {
    src: "images/home/image-42.png",
    original: "images/home/image-42.png",
    width: 320,
    height: 194,
    caption: "",
  },
  {
    src: "images/home/image-43.png",
    original: "images/home/image-43.png",
    alt: "Cosmos Flower",
    width: 320,
    height: 213,
    caption: "",
  },
  {
    src: "images/home/image-44.png",
    original: "images/home/image-44.png",
    width: 271,
    height: 320,
    caption: "",
  },
  {
    src: "images/home/image-45.png",
    original: "images/home/image-45.png",
    width: 320,
    height: 213,
    tags: [
      { value: "", title: "" },
      { value: "", title: "" },
    ],
    caption: "",
  },
  {
    src: "images/home/image-46.png",
    original: "images/home/image-46.png",
    width: 320,
    height: 213,
    tags: [
      { value: "", title: "" },
      { value: "", title: "" },
    ],
    caption: "",
  },
  {
    src: "images/home/image-47.png",
    original: "images/home/image-47.png",
    width: 320,
    height: 213,
    caption: "",
  },
  {
    src: "images/home/image-48.png",
    original: "images/home/image-48.png",
    width: 320,
    height: 213,
    caption: "",
  },
  {
    src: "images/home/image-49.png",
    original: "images/home/image-49.png",
    width: 320,
    height: 179,
    tags: [
      { value: "", title: "" },
      { value: "", title: "" },
    ],
    caption: "",
  },
  {
    src: "images/home/image-50.png",
    original: "images/home/image-50.png",
    width: 320,
    height: 213,
    caption: "",
  },
  {
    src: "images/home/image-51.png",
    original: "images/home/image-51.png",
    width: 320,
    height: 174,
    tags: [
      { value: "guard", title: "guard" },
      { value: "quarter", title: "quarter" },
    ],
    caption: "Inspecting quarter guard at  the AIG office",
  },
  {
    src: "images/home/image-52.png",
    original: "images/home/image-52.png",
    width: 320,
    height: 212,
    caption: "Inspecting quarter guard at  the AIG office",
  },
  {
    src: "images/home/image-53.png",
    original: "images/home/image-53.png",
    width: 320,
    height: 212,
    caption: "Courtesy visit to the AIG",
  },
  {
    src: "images/home/image-54.png",
    original: "images/home/image-54.png",
    width: 320,
    height: 215,
    tags: [{ value: "Police", title: "Police" }],
    caption: "Courtesy visit to the AIG",
  },
  {
    src: "images/home/image-55.png",
    original: "images/home/image-55.png",
    width: 257,
    height: 320,
    caption: "Inspecting quarter guard at  the AIG office",
  },
  {
    src: "images/home/image-56.png",
    original: "images/home/image-56.png",
    width: 226,
    height: 320,
    caption: "Courtesy visit to the AIG",
  },
  {
    src: "images/home/image-57.png",
    original: "images/home/image-57.png",
    width: 320,
    height: 213,
    caption: "Inspecting quarter guard at  the AIG office",
  },
  {
    src: "images/home/image-58.png",
    original: "images/home/image-58.png",
    width: 320,
    height: 183,
    caption: "Inspecting quarter guard at  the AIG office",
  },
  {
    src: "images/home/image-59.png",
    original: "images/home/image-59.png",
    width: 240,
    height: 320,
    tags: [{ value: "Nature", title: "Nature" }],
    caption: "Courtesy visit to the AIG",
  },
  {
    src: "images/home/image-61.png",
    original: "images/home/image-61.png",
    width: 320,
    height: 190,
    caption: "With the AIG",
  },
  {
    src: "images/home/image-62.png",
    original: "images/home/image-62.png",
    width: 320,
    height: 148,
    tags: [{ value: "", title: "" }],
    caption: "",
  },
  {
    src: "images/home/image-63.png",
    original: "images/home/image-63.png",
    width: 320,
    height: 213,
    caption: "",
  },
  {
    src: "images/home/image-64.png",
    original: "images/home/image-64.png",
    alt: "Big Ben - London",
    width: 248,
    height: 320,
    caption: "",
  },
  {
    src: "images/home/image-65.png",
    original: "images/home/image-65.png",
    alt: "Red Zone - Paris",
    width: 320,
    height: 113,
    tags: [{ value: "", title: "" }],
    caption: "",
  },
  {
    src: "images/home/image-66.png",
    original: "images/home/image-66.png",
    alt: "Wood Glass",
    width: 313,
    height: 320,
    caption: "",
  },
  {
    src: "images/home/image-67.png",
    original: "images/home/image-67.png",
    width: 320,
    height: 213,
    caption: "",
  },
  {
    src: "images/home/image-68.png",
    original: "images/home/image-68.png",
    width: 320,
    height: 194,
    caption: "",
  },
  {
    src: "images/home/image-69.png",
    original: "images/home/image-69.png",
    alt: "Cosmos Flower",
    width: 320,
    height: 213,
    caption: "",
  },
  {
    src: "images/home/image-70.png",
    original: "images/home/image-71.png",
    width: 271,
    height: 320,
    caption: "",
  },
  {
    src: "images/home/image-72.png",
    original: "images/home/image-72.png",
    width: 320,
    height: 213,
    tags: [
      { value: "", title: "" },
      { value: "", title: "" },
    ],
    caption: "",
  },
  {
    src: "images/home/image-73.png",
    original: "images/home/image-73.png",
    width: 320,
    height: 213,
    tags: [
      { value: "", title: "" },
      { value: "", title: "" },
    ],
    caption: "",
  },
  {
    src: "images/home/image-74.png",
    original: "images/home/image-74.png",
    width: 320,
    height: 213,
    caption: "",
  },
  {
    src: "images/home/image-75.png",
    original: "images/home/image-75.png",
    width: 320,
    height: 213,
    caption: "",
  },
  {
    src: "images/home/image-76.png",
    original: "images/home/image-76.png",
    width: 320,
    height: 179,
    tags: [
      { value: "", title: "" },
      { value: "", title: "" },
    ],
    caption: "",
  },
  {
    src: "images/home/image-77.png",
    original: "images/home/image-77.png",
    width: 320,
    height: 213,
    caption: "",
  },
  {
    src: "images/home/image-78.png",
    original: "images/home/image-78.png",
    width: 320,
    height: 174,
    tags: [
      { value: "guard", title: "guard" },
      { value: "quarter", title: "quarter" },
    ],
    caption: "Inspecting quarter guard at  the AIG office",
  },
  {
    src: "images/home/image-79.png",
    original: "images/home/image-79.png",
    width: 320,
    height: 212,
    caption: "Inspecting quarter guard at  the AIG office",
  },
  {
    src: "images/home/image-80.png",
    original: "images/home/image-80.png",
    width: 320,
    height: 212,
    caption: "Courtesy visit to the AIG",
  },
  {
    src: "images/home/image-81.png",
    original: "images/home/image-81.png",
    width: 320,
    height: 215,
    tags: [{ value: "Police", title: "Police" }],
    caption: "Courtesy visit to the AIG",
  },
  {
    src: "images/home/image-82.png",
    original: "images/home/image-82.png",
    width: 257,
    height: 320,
    caption: "Inspecting quarter guard at  the AIG office",
  },
  {
    src: "images/home/image-83.png",
    original: "images/home/image-83.png",
    width: 226,
    height: 320,
    caption: "Courtesy visit to the AIG",
  },
  {
    src: "images/home/image-84.png",
    original: "images/home/image-84.png",
    width: 320,
    height: 213,
    caption: "Inspecting quarter guard at  the AIG office",
  },
  {
    src: "images/home/image-85.png",
    original: "images/home/image-85.png",
    width: 320,
    height: 183,
    caption: "Inspecting quarter guard at  the AIG office",
  },
  {
    src: "images/home/image-86.png",
    original: "images/home/image-86.png",
    width: 240,
    height: 320,
    tags: [{ value: "Nature", title: "Nature" }],
    caption: "Courtesy visit to the AIG",
  },
  {
    src: "images/home/image-87.png",
    original: "images/home/image-87.png",
    width: 320,
    height: 190,
    caption: "With the AIG",
  },
  {
    src: "images/home/image-88.png",
    original: "images/home/image-88.png",
    width: 320,
    height: 148,
    tags: [{ value: "", title: "" }],
    caption: "",
  },
  {
    src: "images/home/image-89.png",
    original: "images/home/image-89.png",
    width: 320,
    height: 213,
    caption: "",
  },
  {
    src: "images/home/image-90.png",
    original: "images/home/image-90.png",
    alt: "Big Ben - London",
    width: 248,
    height: 320,
    caption: "",
  },
  {
    src: "images/home/image-91.png",
    original: "images/home/image-91.png",
    alt: "Red Zone - Paris",
    width: 320,
    height: 113,
    tags: [{ value: "", title: "" }],
    caption: "",
  },
  {
    src: "images/home/image-92.png",
    original: "images/home/image-92.png",
    alt: "Wood Glass",
    width: 313,
    height: 320,
    caption: "",
  },
  {
    src: "images/home/image-93.png",
    original: "images/home/image-93.png",
    width: 320,
    height: 213,
    caption: "",
  },
  {
    src: "images/home/image-94.png",
    original: "images/home/image-94.png",
    width: 320,
    height: 194,
    caption: "",
  },
  {
    src: "images/home/image-95.png",
    original: "images/home/image-95.png",
    alt: "Cosmos Flower",
    width: 320,
    height: 213,
    caption: "",
  },
  {
    src: "images/home/image-96.png",
    original: "images/home/image-96.png",
    width: 271,
    height: 320,
    caption: "",
  },
  {
    src: "images/home/image-97.png",
    original: "images/home/image-97.png",
    width: 320,
    height: 213,
    tags: [
      { value: "", title: "" },
      { value: "", title: "" },
    ],
    caption: "",
  },
  {
    src: "images/home/image-98.png",
    original: "images/home/image-98.png",
    width: 320,
    height: 213,
    tags: [
      { value: "", title: "" },
      { value: "", title: "" },
    ],
    caption: "",
  },
  {
    src: "images/home/image-99.png",
    original: "images/home/image-99.png",
    width: 320,
    height: 213,
    caption: "",
  },
  {
    src: "images/home/image-100.png",
    original: "images/home/image-100.png",
    width: 320,
    height: 213,
    caption: "",
  },
  {
    src: "images/home/image-101.png",
    original: "images/home/image-101.png",
    width: 320,
    height: 179,
    tags: [
      { value: "", title: "" },
      { value: "", title: "" },
    ],
    caption: "",
  },
  {
    src: "images/home/image-102.png",
    original: "images/home/image-102.png",
    width: 320,
    height: 213,
    caption: "",
  },
  {
    src: "images/home/image-103.png",
    original: "images/home/image-103.png",
    width: 320,
    height: 174,
    tags: [
      { value: "guard", title: "guard" },
      { value: "quarter", title: "quarter" },
    ],
    caption: "Inspecting quarter guard at  the AIG office",
  },
  {
    src: "images/home/image-104.png",
    original: "images/home/image-104.png",
    width: 320,
    height: 212,
    caption: "Inspecting quarter guard at  the AIG office",
  },
  {
    src: "images/home/image-105.png",
    original: "images/home/image-105.png",
    width: 320,
    height: 212,
    caption: "Courtesy visit to the AIG",
  },
  {
    src: "images/home/image-106.png",
    original: "images/home/image-106.png",
    width: 320,
    height: 215,
    tags: [{ value: "Police", title: "Police" }],
    caption: "Courtesy visit to the AIG",
  },
  {
    src: "images/home/image-107.png",
    original: "images/home/image-107.png",
    width: 257,
    height: 320,
    caption: "Inspecting quarter guard at  the AIG office",
  },
  {
    src: "images/home/image-108.png",
    original: "images/home/image-108.png",
    width: 226,
    height: 320,
    caption: "Courtesy visit to the AIG",
  },
  {
    src: "images/home/image-109.png",
    original: "images/home/image-109.png",
    width: 320,
    height: 213,
    caption: "Inspecting quarter guard at  the AIG office",
  },
  {
    src: "images/home/image-110.png",
    original: "images/home/image-110.png",
    width: 320,
    height: 183,
    caption: "Inspecting quarter guard at  the AIG office",
  },
  {
    src: "images/home/image-111.png",
    original: "images/home/image-111.png",
    width: 240,
    height: 320,
    tags: [{ value: "Nature", title: "Nature" }],
    caption: "Courtesy visit to the AIG",
  },
  {
    src: "images/home/image-112.png",
    original: "images/home/image-112.png",
    width: 320,
    height: 190,
    caption: "With the AIG",
  },
  {
    src: "images/home/image-113.png",
    original: "images/home/image-113.png",
    width: 320,
    height: 148,
    tags: [{ value: "", title: "" }],
    caption: "",
  },
  {
    src: "images/home/image-114.png",
    original: "images/home/image-114.png",
    width: 320,
    height: 213,
    caption: "",
  },
  {
    src: "images/home/image-115.png",
    original: "images/home/image-115.png",
    alt: "Big Ben - London",
    width: 248,
    height: 320,
    caption: "",
  },
  {
    src: "images/home/image-116.png",
    original: "images/home/image-116.png",
    alt: "Red Zone - Paris",
    width: 320,
    height: 113,
    tags: [{ value: "", title: "" }],
    caption: "",
  },
  {
    src: "images/home/image-117.png",
    original: "images/home/image-117.png",
    alt: "Wood Glass",
    width: 313,
    height: 320,
    caption: "",
  },
  {
    src: "images/home/image-118.png",
    original: "images/home/image-118.png",
    width: 320,
    height: 213,
    caption: "",
  },
  {
    src: "images/home/image-119.png",
    original: "images/home/image-119.png",
    width: 320,
    height: 194,
    caption: "",
  },
  {
    src: "images/home/image-120.png",
    original: "images/home/image-120.png",
    alt: "Cosmos Flower",
    width: 320,
    height: 213,
    caption: "",
  },
  {
    src: "images/home/image-121.png",
    original: "images/home/image-121.png",
    alt: "Cosmos Flower",
    width: 320,
    height: 213,
    caption: "",
  },



  {
    src: "images/home/image-122.png",
    original: "images/home/image-122.png",
    alt: "Wood Glass",
    width: 313,
    height: 320,
    caption: "",
  },
  {
    src: "images/home/image-123.png",
    original: "images/home/image-123.png",
    width: 320,
    height: 213,
    caption: "",
  },
  {
    src: "images/home/image-124.png",
    original: "images/home/image-124.png",
    width: 320,
    height: 194,
    caption: "",
  },
  {
    src: "images/home/image-125.png",
    original: "images/home/image-125.png",
    alt: "Cosmos Flower",
    width: 320,
    height: 213,
    caption: "",
  },
  {
    src: "images/home/image-126.png",
    original: "images/home/image-126.png",
    alt: "Cosmos Flower",
    width: 320,
    height: 213,
    caption: "",
  },


  {
    src: "images/home/image-121.png",
    original: "images/home/image-121.png",
    alt: "Cosmos Flower",
    width: 320,
    height: 213,
    caption: "",
  },



  {
    src: "images/home/image-127.png",
    original: "images/home/image-127.png",
    alt: "Wood Glass",
    width: 313,
    height: 320,
    caption: "",
  },
  {
    src: "images/home/image-128.png",
    original: "images/home/image-128.png",
    width: 320,
    height: 213,
    caption: "",
  },
  {
    src: "images/home/image-129.png",
    original: "images/home/image-129.png",
    width: 320,
    height: 194,
    caption: "",
  },
  {
    src: "images/home/image-130.png",
    original: "images/home/image-130.png",
    alt: "Cosmos Flower",
    width: 320,
    height: 213,
    caption: "",
  },
  {
    src: "images/home/image-131.png",
    original: "images/home/image-131.png",
    alt: "Cosmos Flower",
    width: 320,
    height: 213,
    caption: "",
  },
  {
    src: "images/home/image-132.png",
    original: "images/home/image-132.png",
    alt: "Cosmos Flower",
    width: 320,
    height: 213,
    caption: "",
  },
];

export default Images